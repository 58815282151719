require('../css/granatrio.scss');
require('../misc/base.js');

const supportsWebp = async () => {
  if (!self.createImageBitmap) return false;

  const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  const blob = await fetch(webpData).then((r) => r.blob());
  return createImageBitmap(blob).then(
    () => true,
    () => false
  );
};

(async () => {
  if (!(await supportsWebp())) {
    document.documentElement.classList.add('webp-not-supported');
    document.querySelectorAll('[data-fallback-src]').forEach((img) => {
      img.src = img.dataset.fallbackSrc;
    });
  }
})();

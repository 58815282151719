require("@rails/ujs").start();

document.querySelector('.menu-mobile')?.addEventListener('click', ev=>{
  ev.preventDefault();
  document.querySelector('.menu--ul').classList.toggle('menu-open');
});

let telBlur = ()=>{
  clearTimeout(window.telTimeout);
}
document.querySelectorAll('[data-tel]').forEach(el=>{
  el.addEventListener('click', ev=>{
    let target = ev.currentTarget;
    ev.preventDefault();
    window.telTimeout = setTimeout(()=>{
      target.removeEventListener('blur', telBlur);
      window.location.href = target.href;
    }, 500);

    window.location = `tel:${target.dataset.tel}`;
    target.addEventListener('blur', telBlur);
  });
});
